import React from 'react'
import Layout from '../components/layout'

export default () => {
  const bannerConfig = {
    title: 'About',
    imgUrl: '',
  }

  return <Layout bannerConfig={bannerConfig}>About me</Layout>
}
